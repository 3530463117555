import { makeStyles } from "@material-ui/core";
import React, {useEffect} from "react";
import Footer from "../components/Footer";
import { useHistory } from "react-router-dom";
import {
  Privacy,
  DetailsListfirst,
  DetailsListSecond,
  DetailsListThird,
  PrivacyList,
  PrivacyDetails,
  privacyDetailsData,
} from "../constant/privacy";
import ReactGA from 'react-ga';

const useStyles = makeStyles((theme) => ({
  fullPage: {
    width: "100vw",
    minHeight: "-webkit-fill-available",
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "column",
  },
  policy_heading: {
    fontSize: '30px',
    marginBottom : '20px'
  },
  restOfPage: {
    margin : '0px 10vw',  
    padding: 20,
    minHeight: "100vh",
    paddingTop: "2vh",
  },
  information_gathered: {
    fontSize: '17px',

    paddingRight : '10px',
    information_gathered_by: {
      fontSize: '8px !immportant' ,
      marginTop : '30px'
    }
  },
  disclose_information : {
  marginTop : '20px'
  },
  information_para : {
   fontSize : '15px',
   marginTop : '20px' 
  }

}));

const PrivacyPage = (props) => {
  const classes = useStyles();
  const history = useHistory();
  ReactGA.initialize('GTM-WZCJ3Q3');
 
  useEffect(() =>{
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);

   },[])
  return (
    <div className={classes.fullPage}>

      <div className={classes.restOfPage}>
        <div>
          <div className={classes.policy_heading}>Privacy Policy</div>
          <span className={classes.information_gathered}>INFORMATION GATHERED BY</span><span className={classes.information_gathered_by}>iski.skmdsports.com. This is iski.skmdsports.com’s (“iski.skmdsports.com”) online privacy policy (“Policy”). This policy applies only to activities iski.skmdsports.com engages in on its website and does not apply to iski.skmdsports.com activities that are “offline” or unrelated to the website.</span>
          <div className={classes.information_para}>iski.skmdsports.com collects certain anonymous data regarding the usage of the website. This information does not personally identify users, by itself or in combination with other information, and is gathered to improve the performance of the website. The anonymous data collected by the iski.skmdsports.com website can include information such as the type of browser you are using, and the length of the visit to the website. You may also be asked to provide personally identifiable information on the iski.skmdsports.com website, which may include your name, address, telephone number and e-mail address. This information can be gathered when feedback or e-mails are sent to iski.skmdsports.com, when you register for services, or make purchases via the website. In all such cases you have the option of providing us with personally identifiable information.</div>
          <div className={classes.disclose_information}><span className={classes.information_gathered}>1. USE AND DISCLOSURE OF INFORMATION.</span><span className={classes.information_gathered_by}>Except as otherwise stated below, we do not sell, trade or rent your personally identifiable information collected on the site to others. The information collected by our site is used to process orders, to keep you informed about your order status, to notify you of products or special offers that may be of interest to you, and for statistical purposes for improving our site. We will disclose your Delivery information to third parties for order tracking purposes or process your check or money order, as appropriate, fill your order, improve the functionality of our site, perform statistical and data analyses deliver your order and deliver promotional emails to you from us. For example, we must release your mailing address information to the delivery service to deliver products that you ordered.</span></div>        
          <div className={classes.disclose_information}><span className={classes.information_gathered_by}>2. Https://iski.skmdsports.com will not pass any debit/credit card details to third parties. All credit/debit cards’ details and personally identifiable information will NOT be stored, sold, shared, rented or leased to any third parties</span></div>        
          <div className={classes.disclose_information}><span className={classes.information_gathered}>COOKIES. </span><span className={classes.information_gathered_by}>Cookies are small bits of data cached in a user’s browser. iski.skmdsports.com utilizes cookies to determine whether or not you have visited the home page in the past. However, no other user information is gathered.</span></div>        
          <div className={classes.disclose_information}><span className={classes.information_gathered_by}>iski.skmdsports.com may use non-personal “aggregated data” to enhance the operation of our website, or analyze interest in the areas of our website. Additionally, if you provide iski.skmdsports.com with content for publishing or feedback, we may publish your user name or other identifying data with your permission.</span></div>        
          <div className={classes.disclose_information}><span className={classes.information_gathered_by}>iski.skmdsports.com may also disclose personally identifiable information in order to respond to a subpoena, court order or other such request. iski.skmdsports.com may also provide such personally identifiable information in response to a law enforcement agencies request or as otherwise required by law. Your personally identifiable information may be provided to a party if iski.skmdsports.com files for bankruptcy, or there is a transfer of the assets or ownership of iski.skmdsports.com in connection with proposed or consummated corporate reorganizations, such as mergers or acquisitions.</span></div>        
          <div className={classes.disclose_information}><span className={classes.information_gathered}>3. SECURITY.</span><span className={classes.information_gathered_by}>iski.skmdsports.com takes appropriate steps to ensure data privacy and security including through various hardware and software methodologies. However, iski.skmdsports.com cannot guarantee the security of any information that is disclosed online.</span></div>        
          <div className={classes.disclose_information}><span className={classes.information_gathered}>4. OTHER WEBSITES.</span><span className={classes.information_gathered_by}>iski.skmdsports.com is not responsible for the privacy policies of websites to which it links. If you provide any information to such third parties different rules regarding the collection and use of your personal information may apply. We strongly suggest you review such third party’s privacy policies before providing any data to them. We are not responsible for the policies or practices of third parties. Please be aware that our sites may contain links to other sites on the Internet that are owned and operated by third parties. The information practices of those Web sites linked to our site is not covered by this Policy. These other sites may send their own cookies or clear GIFs to users, collect data or solicit personally identifiable information. We cannot control this collection of information. You should contact these entities directly if you have any questions about their use of the information that they collect.</span></div>        
          <div className={classes.disclose_information}><span className={classes.information_gathered_by}>MINORS. iski.skmdsports.com does not knowingly collect personal information from minors under the age of 18. Minors are not permitted to use the iski.skmdsports.com website or services, and iski.skmdsports.com requests that minors under the age of 18 not submit any personal information to the website. Since information regarding minors under the age of 18 is not collected, iski.skmdsports.com does not knowingly distribute personal information regarding minors under the age of 18</span></div>        
          <div className={classes.disclose_information}><span className={classes.information_gathered_by}>CORRECTIONS AND UPDATES. If you wish to modify or update any information iski.skmdsports.com has received, please contact iskicenter@gmail.com</span></div>        
          <div className={classes.disclose_information}><span className={classes.information_gathered}>5. MODIFICATIONS OF THE PRIVACY POLICY.</span><span className={classes.information_gathered_by}> iski.skmdsports.com. The Website Policies and Terms & Conditions would be changed or updated occasionally to meet the requirements and standards. Therefore the Customers’ are encouraged to frequently visit these sections in order to be updated about the changes on the website. Modifications will be effective on the day they are posted”.</span></div>        
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPage;
