import { makeStyles, TextField, OutlinedInput } from '@material-ui/core';
import React from 'react';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
    title2: {
        fontSize: 24,
        fontWeight: 'normal',
        fontFamily: "airbnb-cereal-app",
        color: '#080133',
        textAlign: 'center'
    },
    info: {
        background: "#FFFFFF",
        boxShadow: "0px 6px 40px rgba(208, 208, 208, 0.2)",
        borderRadius: "16px",
        padding: "10px 0",
    },
    orderDetail: {
        margin: 0,
        marginLeft: 10,
        marginTop: -2,
        marginBottom: 6,
        fontWeight: 'normal',
        fontSize: '16px',
        fontStyle: 'normal',
        fontFamily: 'airbnb-cereal-app',
        color: '#808080',
        wordWrap : 'break-word'

    },
    orderDetailLabel: {
        marginLeft: 10,
        fontFamily: 'airbnb-cereal-app',
        fontSize: 18,
        fontWeight: 'normal',
        fontStyle: 'normal',
        color: '#080133'
    },
}));

const BookingDetails = props => {
    const classes = useStyles();
   
    return (
        <div >
            <p className={classes.title2}>Booking Details</p>
            <div className={classes.info}>
                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={6}>
                        <Grid container >
                            <Grid >
                                <p className={classes.orderDetailLabel}>Company </p>
                                <p className={classes.orderDetail}>{props.companyLabel}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    {props.bookingCode ? <Grid item xs={6}>
                        <Grid container>
                            <Grid >
                                <p className={classes.orderDetailLabel}>Booking Id </p>
                                <p className={classes.orderDetail}>{props.bookingCode}</p>
                            </Grid>
                        </Grid>
                    </Grid> : null}
                    <Grid item xs={6}>
                        <Grid container >
                            <Grid >
                                <p className={classes.orderDetailLabel}>Location </p>
                                <p className={classes.orderDetail}>{props.branchName}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container >
                            <Grid >
                                <p className={classes.orderDetailLabel}>Date </p>
                                <p className={classes.orderDetail}>{props.bookingTimeSelectedDate}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container >
                            <Grid >
                                <p className={classes.orderDetailLabel}>Service </p>
                                <p className={classes.orderDetail}>{props.serviceName}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container >
                            <Grid >
                                <p className={classes.orderDetailLabel}>Start Time </p>
                                <p className={classes.orderDetail}>{props.bookingTimeStartSlotTime}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                   
                    <Grid item xs={6}>
                        <Grid container >
                            <Grid >
                                <p className={classes.orderDetailLabel}>Price </p>
                                <p className={classes.orderDetail}>{props.servicePrice}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default BookingDetails;