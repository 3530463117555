import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker as MateruialUIDatePicker,
  KeyboardDatePicker 
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core';
import {ReactComponent as Calendar } from '../assets/images/Calendar.svg';
import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    datePicker: {
        margin: 0,
        height: 58,
        backgroundColor: 'white !important',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        color: '#535050 !important',
        border: '1px solid #535050',
        width: 240,
        marginBottom: 15,
        borderRadius: 14,
        overflow: 'hidden',
        [theme.breakpoints.up('md')]: {
            borderRadius: 0,
            marginBottom: 0,
            borderLeft: 0,
            borderRight: 0,
            boxShadow:'none'
        },
    },
    datePickerCalendar: {
        margin: 0,
        height: 58,
        color: '#535050 !important',
        border: '1px solid #535050',
        width: 240,
        marginBottom: 0,
        borderRadius: 10,
        overflow: 'hidden',
    },
    input: {
        backgroundColor: 'white !important',
        fontFamily: 'Martel Sans',
        height: 60,
        color: '#535050 !important',
        [theme.breakpoints.down('md')]: {
            paddingLeft: 10,
        },
    },
    inputCalendar: {
        backgroundColor: 'white !important',
        fontFamily: 'Martel Sans',
        height: 60,
        color: '#535050 !important',
        paddingLeft: 10,
    },
    label: {
        backgroundColor: 'white !important',
        color: '#535050 !important',
        fontFamily: 'Martel Sans',
        transform: 'translate(0px, 10px) scale(0.75)',
        zIndex: 1,
        [theme.breakpoints.down('md')]: {
            transform: 'translate(10px, 10px) scale(0.75)',
        },
    },
    labelCalendar: {
        backgroundColor: 'white !important',
        fontFamily: 'Martel Sans',
        color: '#535050 !important',
        transform: 'translate(10px, 10px) scale(0.75)',
        zIndex: 1,
    },
    input: {
        color: "red",
      '&::before': {
        border  : 'none'
      }   
    },

}));

const materialTheme = createMuiTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: "#5182FF",
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                backgroundColor: "#fff",
                color: "#5182FF",
            },
        },
        MuiPickersDay : {
            daySelected : {
                backgroundColor: "#5182FF",
            }
        }
    },
});

const DatePicker = props => {

    const classes = useStyles();
    function disableRandomDates() {
        return Math.random() > 0.7;
      }
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={materialTheme}>
            <KeyboardDatePicker
                minDate = {new Date()}
                variant="inline"
                format="MM/dd/yyyy"
                margin="none"
                label=""
                value={props.date}
                onChange={props.handleChange}
                autoOk={true}
                keyboardIcon={<Calendar />}
                className="styledKeyboardDatePicker"
                keyboardbuttonprops={{
                    'aria-label': 'change date',
                }}
                inputProps={{
                    style : { visibility : "hidden", width : '0'}
                }}

                InputLabelProps={{
                    shrink: true,
                }}

                InputProps={{
                  className: classes.input,
                }}
            />

        </ThemeProvider>
        </MuiPickersUtilsProvider>
    );
}

export default DatePicker;