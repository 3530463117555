import { Button, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import CustomButton from "../components/CustomButton";
import { useHistory } from "react-router-dom";
import Footer from "../components/Footer";
// import frontend from "../config/skmdsports";
import { BASE_URL } from "../api/baseurl";
import { setLocalStorageData } from "../util/localStorageService";
import Axios from "axios";
import Grid from "@material-ui/core/Grid";
// import { ReactComponent as Logo } from "../assets/images/wakelogo.svg";
// import { allFeatureDetails } from "../constant/AllFeatureDetails";
import Background from "../assets/images/background1.png";
import Mobilebackground from "../assets/images/mobilebackground.png";
import ReactGA from "react-ga";
import image1 from "../assets/images/image1.png";
import image2 from "../assets/images/image2.png";
import image3 from "../assets/images/image3.png";
import image4 from "../assets/images/image4.png";
import HomepageHeader from "../components/HomepageHeader";

const useStyles = makeStyles((theme) => ({
  fullPage: {
    width: "100vw",
    height: "100vh",
    margin: 0,
    padding: 0,
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${Mobilebackground})`,
      opacity: 0.8,
      height: "100vh !important",
      backgroundPosition: "center",
      objectFit: "cover",
      margin: 0,
      padding: 0,
    },
    [theme.breakpoints.down("md")]: {
      backgroundImage: `url(${Mobilebackground})`,
      opacity: 0.8,
      height: "100vh !important",
      backgroundSize: "100% 100%",
      objectFit: "cover",
      margin: 0,
      padding: 0,
    },
  },
  fullContainer: {
    width: "100vw",
    minHeight: "-webkit-fill-available",
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  gridContainer: {
    display: "flex",
    justifyContent: " space-around",
    marginTop: "25px",
  },
  gridContainerodd: {
    display: "flex",
    justifyContent: " space-around",
    marginBottom: "2rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  searchButtom: {
    height: 45,
    fontSize: "20px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontFamily: "airbnb-cereal-app-light !import",
    textTransform: "capitalize",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 8px 24px rgba(215, 215, 215, 0.25)",
    color: "#808080",
    marginLeft: 5,
    borderRadius: "8px",
    overflow: "hidden",
    marginLeft: "130px",
    padding: "0px 30px",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginTop: 40,
      width: "auto",
      fontSize: "20px",
      fontWeight: "500",
      lineHeight: "140%",
      marginTop: "20px",
      padding: "6px 30px",
      height: "auto",
    },
  },
  homeHeading: {
    padding: "30px 20px",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      padding: "20px 30px",
      flexDirection: "column",
    },
  },
  homeText: {
    fontWeight: "bold",
    fontStyle: "normal",
    fontFamily: "airbnb-cereal-app",
    fontSize: "32px",
    color: "#FFFFFF",
    marginTop: "0px",
    marginLeft: "20px",
    width: "80vw",
    lineHeight: "120%",
    [theme.breakpoints.down("md")]: {
      marginTop: "0px",
      fontSize: "20px",
      marginLeft: "0px",
    },
  },
  paragraphtag: {
    background: "rgba(196, 196, 196, 0.45)",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "25px",
    lineHeight: "31px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  headingfirst: {
    fontFamily: "Airbnb Cereal App",
    fontWeight: "500",
    fontSize: "22px",
    paddingLeft: "10px",
  },
  parafirst: {
    fontFamily: "Airbnb Cereal App",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "120%",
    color: "#808080",
    margin: "0",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  donotdisplay: {
    marginTop: "20px",
    paddingLeft: "16rem",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "10rem",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  xsdisplay: {
    display: "none",
    paddingLeft: "30px",
    paddingRight: "30px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
  },
  Booknowbtn: {
    marginTop: "20px",
    paddingLeft: "16rem",
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
      paddingLeft: "85px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
      paddingLeft: "30px",
    },
  },
  images: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: "20px",
      paddingRight: "20px",
      paddingLeft: "20px",
    },
  },
  imagescurve: {
    borderRadius: "15px",
  },
  imagepadding: {
    paddingTop: "125px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "10px",
      paddingRight: "20px",
      paddingLeft: "20px",
    },
  },
  rightsidecontent: {
    paddingTop: "30px",
    [theme.breakpoints.down("md")]: {
      paddingTop: "0",
    },
  },
}));

const HomePage = () => {
  const [locationList, setLocationList] = useState([]);
  const [serviceList, setServiceList] = useState([]);

  const history = useHistory();
  const myRef = useRef(null);
  ReactGA.initialize("UA-289359672-01");

  useEffect(() => {
    localStorage.clear();
    const getCompaniesList = async () => {
      const { data } = await Axios.post(`${BASE_URL}/getCompanies`);
      if (data.error === 0) {
        const selectCompany = data.data.filter(
          (val) => val.name === "iSKI Center"
        );
        const list = selectCompany[0]?.branch?.map(({ name, id }) => {
          return {
            label: name,
            value: id,
          };
        });
        setLocationList(list);
        setLocalStorageData("locationList", list);

        const serviceListItem = selectCompany[0]?.branch[0].services?.map(
          ({ name, id }) => {
            return {
              label: name,
              value: id,
            };
          }
        );
        setLocalStorageData("serviceList", serviceListItem);
        setServiceList(serviceListItem);
      }
    };
    getCompaniesList();
    ReactGA.pageview(window.location.pathname + window.location.search);
    window.scrollTo(0, 0);
  }, []);
  const company = { label: "iSKI Center", value: "Jywrt74o8uuD3A4yJSvZ" };
  const handleButton = async () => {
    setLocalStorageData("locationList", locationList);
    setLocalStorageData("selectedCompany", company);
    setLocalStorageData("selectedLocation", {
      label: "Palm Deira",
      value: "wSiTzlzbGw3I7YDAzVCe",
    });
    setLocalStorageData("selectedService", serviceList[0]);
    setLocalStorageData("serviceList", serviceList);
    history.push({ pathname: "/calendar" });
  };
  const classes = useStyles();
  return (
    <div>
      <HomepageHeader />
      <div className={classes.fullPage}>
        <div className={classes.homeHeading}>
          <div style={{ marginTop: "56px" }}>
            <div className={classes.homeText}>
              iSKI now open in Palm <br /> Deira
            </div>
            <Button
              variant="contained"
              className={classes.searchButtom}
              onClick={handleButton}
              startIcon=""
            >
              Book Online
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.fullContainer}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} md={4}>
            <h1 className={classes.headingfirst}>Welcome to the home of</h1>
            <h1 className={classes.headingfirst}>Water Ski in Dubai</h1>
            <p className={classes.parafirst}>
              <p>
                This website is for Water Ski enthusiasts that are looking to
                spend time on the water maneuvering our state of the art slalom
                course. If that’s you, then you are in luck!! We just opened our
                newest location ensuring the best water conditions for you.
              </p>
              <p>
                If you know the name iSKI then you must know that we are a
                community that started nearly 30 years ago within Ghantoot and
                made its way to the calm waters of Palm Deira providing a great
                experience.{" "}
              </p>{" "}
              <p>
                Our boat is a Ski Nautique that is built for a great Water Ski
                Experience
              </p>
            </p>
          </Grid>
          <Grid item xs={12} md={4} className={classes.imagepadding}>
            <img src={image2} alt="Ski" className={classes.imagescurve}/>
          </Grid>
        </Grid>

        <Grid container className={classes.gridContainerodd}>
          <Grid item xs={12} md={4} className={classes.images}>
            <img src={image1} alt="Ski" className={classes.imagescurve} />
          </Grid>
          <Grid item xs={12} md={4} className={classes.rightsidecontent}>
            <h1 className={classes.headingfirst}>Our new home in Palm Deira</h1>
            <p className={classes.parafirst}>
              Starting April 1st, we are welcoming the passionate Water Ski
              community to our new location that is optimized for Water Ski.
              Within the calm waters of Palm Deira you are guaranteed flat water
              experience, a beautiful beach and a shower to rinse off after your
              ride.
            </p>{" "}
            <br />
            <p className={classes.parafirst}>
              No, traffic is not an issue. With Dubai’s new infinity bridge
              going in and out of Palm Deira is a piece of cake.
            </p>
            <Grid className={classes.donotdisplay}>
              <CustomButton onClick={handleButton}>Go to Maps</CustomButton>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.paragraphtag}>
          <p>
            Beginner? Intermediate? Who cares? Let’s have fun on the <br />
            water. Join us for a Water Ski session and get soaked in some <br />
            Vitamin Sea!!
          </p>
        </div>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} md={4}>
            <h1 className={classes.headingfirst}>
              The Homologated Slalom Course
            </h1>
            <p className={classes.parafirst}>
              If you don’t know what a Slalom course is, keep reading, and if
              you do - well we got a surprise for you!!
              <p>
                Within our new location, we were able to extend a course that is
                500 meters long, ideal for both learning and competing for our
                community of professionals.
              </p>
              <p>
                A Slalom course is basically a zig-zag or wavy course with
                obstacles that water skiers tend to maneuver across. It is
                usually timed, and those that can go the fastest usually are the
                winners.{" "}
              </p>
              <p>
                We have seen records broken in our course and can’t wait to see
                more.
              </p>
            </p>
            <div className={classes.Booknowbtn}>
              <CustomButton onClick={handleButton}>Book Now</CustomButton>
            </div>
          </Grid>
          <Grid item xs={12} md={4} className={classes.images}>
            <img src={image4} alt="Ski" className={classes.imagescurve} />
          </Grid>
        </Grid>
        <Grid container className={classes.gridContainerodd}>
          <Grid item xs={12} md={4} className={classes.images}>
            <img src={image3} alt="Ski" className={classes.imagescurve} />
          </Grid>
          <Grid item xs={12} md={4} className={classes.rightsidecontent}>
            <h1 className={classes.headingfirst}>Our Community</h1>
            <p className={classes.parafirst}>
              Over the past years we have built a community of passionate Water
              Skiers both residing in Dubai and dropping in from countries all
              over the world including England, France, Sybia, Greece, Canada,
              Turkey and the United States.
            </p>
            <br />
            <p className={classes.parafirst}>
              We have had many beginners get up and learn how to navigate our
              Slalom Course. We also have professionals break their own records
              behind our boats.
            </p>
            <Grid className={classes.donotdisplay}>
              <CustomButton onClick={handleButton}>
                Follow Us on IG
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Grid className={classes.xsdisplay}>
        <CustomButton onClick={handleButton}>Book Now</CustomButton>
      </Grid>
      <Footer homePage={true} />
    </div>
  );
};
export default HomePage;
