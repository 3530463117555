import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import CalendarPage from "./pages/CalendarPage";
import CheckoutPage from "./pages/CheckoutPage";
import ConfirmationPage from "./pages/ConfirmationPage";
import TermsPage from "./pages/TermsPage";
// import RefundPolicyPage from "./pages/RefundPolicyPage";
import RefundPolicyPage from "./fonts/RefundPolicyPage";
import PrivacyPage from "./pages/PrivacyPage";
import ContactPage from "./pages/ContactPage";
import AboutUsPage from "./pages/AboutUsPage";
import CancellationPage from "./pages/CancellationPage";
import CancelationStatusPage from "./pages/CancellationStatusPage";
// import Header from "./components/Header";
// import frontend from "./config/skmdsports";
import PaymentUnsuccess from "./pages/PaymentUnsuccess";
// import { Helmet } from "react-helmet";
// import TagManager from "react-gtm-module";
import { HelmetProvider } from "react-helmet-async";
// import Page from "./pages/Page";
import MetaTitle from "./pages/MetaTitle";
import HomepageHeader from "./components/HomepageHeader";
import IskiHomPage from "./components/Iski_2.1/index";
// import { getDataFromTree } from 'react-apollo';

// import template from 'server/template';

// const tagManagerArgs = {
//   gtmId: 'GTM-WZCJ3Q3'
// }
// TagManager.initialize(tagManagerArgs)
function App() {
  // window.dataLayer.push({
  //   event: 'pageview',
  //   // page: {
  //   //   url: window.location.pathname,
  //   //   title: window.location.pathname
  //   // }
  // });

  // render={(props) => (
  //   <Page title="mera title">
  //     <CalendarPage {...props} />
  //   </Page>
  // )}

  return (
    <>
      <HelmetProvider prioritizeSeoTags>
        <Router>
          <Switch>
            <Route path="/calendar">
              <MetaTitle title="Calendar l Wake2Wake l Wakesurfing Wakeboarding" />
              <CalendarPage />
            </Route>
            <Route path="/checkout">
              <MetaTitle title="iSKI - Booking Details" />
              <CheckoutPage />
            </Route>
            <Route path="/confirmation">
              <MetaTitle title="Booking Confirmation l Wake2Wake" />
              <ConfirmationPage />
            </Route>
            <Route path="/terms">
              <TermsPage />
            </Route>
            <Route path='/refund'>
              <RefundPolicyPage />
            </Route>
            <Route path="/privacy">
              <PrivacyPage />
            </Route>
            <Route path="/contact">
              <ContactPage />
            </Route>
            <Route path="/aboutus">
              <AboutUsPage />
            </Route>
            <Route path="/cancellation">
              <MetaTitle title="Cancel Booking l Wake2Wake" />
              <CancellationPage />
            </Route>
            <Route path="/paymentUnsuccess">
              <MetaTitle title="Payment Not Successful l Wake2Wake" />
              <PaymentUnsuccess />
            </Route>
            <Route path="/status">
              <CancelationStatusPage />
            </Route>
            <Route exact path="/">
              <IskiHomPage />
              {/* <IskiHomePage /> */}
              {/* <HomePage /> */}
            </Route>
            <Route>
              <HomepageHeader path="/homeNavbar" />
            </Route>
          </Switch>
        </Router>
      </HelmetProvider>
    </>
  );
}

export default App;
