import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CancellationStatusDetailsPage from '../components/CancellationStatusDetailsPage';
import ReactGA from 'react-ga';
import MetaTitle from "./MetaTitle";

const CancelationStatus = () => {
  const [cancelation, setCancelation] = useState({
    Error : '1',
    Message : ''
  });

  ReactGA.initialize('GTM-WZCJ3Q3');
  useEffect(() =>{
      ReactGA.pageview(window.location.pathname + window.location.search);
  },[])
  
  const history = useHistory();
  useEffect(() => {
    if (history ?.location ?.state ?.Error ) {
      setCancelation({...cancelation, Error : history ?.location ?.state ?.Error, Message : history ?.location ?.state ?.Message })
    }
  }, [history]);

  return (
    <>
    <MetaTitle title={cancelation.Error == '0' ? "Cancellation Confirmation l Iski" : "Cancellation unsuccessful l Iski"} />  
    <div>
      {cancelation.Error == '0' ?
       <CancellationStatusDetailsPage state={history.location.state} bookingStatusHeading="Booking Cancelled" bookingStatusPara="Your booking is cancelled and a refund is being processed. Refunds can take 7-14 working days to reflect in your account ." /> :
       <CancellationStatusDetailsPage state={history.location.state}  bookingStatusHeading="Booking NOT Cancelled" bookingStatusPara='Booking <strong>cannot </strong> be cancelled because the booking start time is in less than 48 hours .' />}
    </div>
    </>
  );
};

export default CancelationStatus;
