import React, {useEffect} from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Footer from "../components/Footer";
import CustomButton from "../components/CustomButton";
import { ReactComponent as Group } from '../assets/images/Group.svg';
import ReactGA from 'react-ga';
import MetaTitle from "./MetaTitle";

const useStyles = makeStyles((theme) => ({
  fullPage: {
    width: "100vw",
    minHeight: "-webkit-fill-available",
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  restOfPage: {
    width: "95%",
    maxWidth: "1100px",
    display: "flex",
    flex: 1,
    padding: '20px 0px',
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },

  imageHeading: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '8px',
    padding: '20px',
    marginTop: '70px',
    marginBottom: '70px'
  },
  backToHome: {
    width: "70%",
    paddingTop: 30,
    paddingBottom: 10,
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  confirmMessage: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '70vw',
  },
  confirmMessageText: {
    textAlign: 'center',
    padding: '0px 12px',
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#808080',
    fontFamily: 'airbnb-cereal-app-light',
    margin: '0px 0px',
  },
  confirmationCode: {
    textAlign: 'center',
    padding: '0px 10px',
    fontSize: 24,
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#080133',
    fontFamily: 'airbnb-cereal-app',
    margin: '5px 0px',
  },

}));

const PaymentUnsucess = () => {
  const classes = useStyles();
  const history = useHistory();
  ReactGA.initialize('GTM-WZCJ3Q3');
  useEffect(() =>{
      ReactGA.pageview(window.location.pathname + window.location.search);
  },[])
  const handleBacktoHome = () => {
    history.push("/");
  };

  return (
    <>
      <MetaTitle title="Payment Not Successful l Iski"/>
    <div className={classes.fullPage}>
      <div className={classes.restOfPage}>
        <div className={classes.imageHeading}>
          <Group />
        </div>
        <div className={classes.confirmMessage}>
          <p className={classes.confirmationCode}>Oops...Payment not processed </p>
          <p className={classes.confirmMessageText}>Unfortunately we were not able to process your payment. Please try again. </p>
        </div>
        <div className={classes.backToHome}>
          <CustomButton
            onClick={handleBacktoHome}
            confirm={true}
          >
            back to home
          </CustomButton>
        </div>
      </div>

      <Footer />
    </div>
    </>
  );
};

export default PaymentUnsucess;
