import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import logo from "../../src/assets/images/Rectangle 3511.png";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  homenavbar: {
    paddingLeft: "20px",
  },
  Navcontainer: {
    display: "flex",
    justifyContent: "space-between",
    background: "white",
    color: "black",
    alignItems: "center",
  },
  lists: {
    textDecoration: "none",
    color: "black",
    fontSize: "15px",
    padding: "30px",
  },
  menulist: {
    paddingTop: "30px",
  },
  icon: {
    color: "white",
  },
  navText: {
    fontsize: "30px",
    fontWeight: "600",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  navTextMobile: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      fontSize: "10px",
      fontWeight: "600",
      marginLeft: "-40px",
    },
  },
}));
const HomepageHeader = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div>
      <div className={classes.homenavbar}>
        <Grid container spacing={2} className={classes.Navcontainer}>
          <Grid item xs={3}>
            <img src={logo} alt="Ski" />
          </Grid>
          <Grid item xs={4}>
            <p className={classes.navText}>
              iSKI - Home of Water Skiing in Dubai
            </p>
            <p className={classes.navTextMobile}>Home of Water Ski</p>
          </Grid>
          {isMobile ? (
            <>
              <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
                <List>
                  <ListItem onClick={() => setOpenDrawer(false)}>
                    <ListItemText>
                      <Link to="/" className={classes.lists}>
                        Our Location
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem onClick={() => setOpenDrawer(false)}>
                    <ListItemText>
                      <Link to="/about" className={classes.lists}>
                        Our Story
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem onClick={() => setOpenDrawer(false)}>
                    <ListItemText>
                      <Link to="/contact" className={classes.lists}>
                        iSKI Booking
                      </Link>
                    </ListItemText>
                  </ListItem>
                </List>
              </Drawer>
              <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
                <MenuIcon />
              </IconButton>
            </>
          ) : (
            <>
              <Grid item xs={4} className={classes.menulist}>
                <Link to="/" className={classes.lists}>
                  Our Location
                </Link>
                <Link to="/about" className={classes.lists}>
                  Our Story
                </Link>
                <Link to="/contact" className={classes.lists}>
                  iSKI Booking
                </Link>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default HomepageHeader;
