import { makeStyles } from '@material-ui/core';
import React from 'react';
import * as moment from 'moment';

const useStyles = makeStyles(theme => ({
    selected: {
        borderRadius: 12,
        cursor: 'pointer',
        boxShadow: '0px 10px 40px #D4D9E8',
        backgroundColor: '#5182FF',

        [theme.breakpoints.up('xs')]: {
            margin: '0px 4px'
        },
    },
    unSelected: {
        borderRadius: 12,
        cursor: 'pointer',
        boxShadow: ' 0px 6px 40px rgba(208, 208, 208, 0.2)',
        backgroundColor: '#FFFFFF',

        [theme.breakpoints.up('xs')]: {
            margin: '0px 4px'
        },

    },
    selectedText: {
        fontFamily: 'airbnb-cereal-app',
        color: '#fff',
        fontWeight: '500',
        textAlign: 'center',
        fontSize: '18px',
        fontStyle: 'normal',
        padding: '0px 18px',
        lineHeight: '10px',

    },
    unSelectedText: {
        fontFamily: 'airbnb-cereal-app',
        color: '#808080',
        fontWeight: '500',
        textAlign: 'center',
        fontSize: '18px',
        fontStyle: 'normal',
        padding: '0px 18px',
        lineHeight: '10px',
    },
    disabled: {
        borderRadius: 12,
        cursor: 'not-allowed',
        boxShadow: ' 0px 6px 40px rgba(208, 208, 208, 0.2)',
        backgroundColor: '#FFFFFF',
        opacity: "0.3",
        [theme.breakpoints.up('xs')]: {
            margin: '0px 4px'
        },
    }
}));

const DayChip = props => {
    const selectedDates = moment(props.selectedDate).format('DD');
    const dateListItems =  moment(props.data).format('DD');
    console.log(selectedDates,'ssssssstuuuu',dateListItems)
    const classes = useStyles();
    return (
        <div className={(selectedDates == dateListItems) ? classes.selected  : classes.unSelected  } onClick={() =>props.onClick(props.data.toDate())}>
            <p className={(selectedDates == dateListItems) ? classes.selectedText :  classes.unSelectedText }>{moment(props.data).format('ddd')}</p>
            <p className={(selectedDates == dateListItems) ? classes.selectedText :  classes.unSelectedText  }>{moment(props.data).format('DD')}</p>
        </div>
       
    );
};

export default DayChip;