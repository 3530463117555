import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  chip: {
    borderRadius: 10,
    padding: "15px",
    cursor: "pointer",
    margin: 5,
    marginBottom: 10,
    background: "#5182FF",
    boxShadow: "0px 6px 40px rgba(208, 208, 208, 0.2)",
    borderRadius: "8px",
  },
  unSelectChip: {
    borderRadius: 10,
    padding: "15px",
    cursor: "pointer",
    margin: 5,
    marginBottom: 10,
    background: "#FFFFFF",
    boxShadow: "0px 6px 40px rgba(208, 208, 208, 0.2)",
    borderRadius: "8px",
  },
  text: {
    fontSize: 14,
    fontFamily: "airbnb-cereal-app-light",
    color: "#ffffff",
    fontWeight: "normal",
    fontSize: "14px",
    textAlign: "center",
    lineHeight: "140%",
    margin: 0,
  },
  unSelectText: {
    fontSize: 14,
    fontFamily: "airbnb-cereal-app-light",
    color: "#000000",
    fontWeight: "normal",
    fontSize: "14px",
    textAlign: "center",
    lineHeight: "140%",
    margin: 0,
  },
}));

const TimeChip = (props) => {
  const classes = useStyles();

  return (
    <div
      className={
        props.selectTime == props.time ? classes.chip : classes.unSelectChip
      }
      onClick={props.onClick}
    >
      <p
        className={
          props.selectTime == props.time ? classes.text : classes.unSelectText
        }
      >
        {`${props.time}`}
      </p>
    </div>
  );
};

export default TimeChip;
