import {
  FormControl,
  InputLabel,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import NativeSelect from "@material-ui/core/NativeSelect";

const useStyles = makeStyles((theme) => ({
  formControlHome: {
    width: 250,
  },
  formControlCalener: {
    width: 170,
    [theme.breakpoints.up("md")]: {
      width: 240,
    },
  },
  label: {
    fontFamily: "airbnb-cereal-app",
    fontSize: '16px',
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },

  selectItem: {
    fontFamily: "airbnb-cereal-app",
    color: "#000000 !important",

  },
  selectLeft: {
    backgroundColor: "white !important",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    color: "#535050 !important",
    border: "1px solid #535050",
    height: 60,
    fontFamily: "Martel Sans",
    overflow: "hidden",
    marginBottom: 15,
    marginRight: 8,
    borderRadius: 14,
    marginTop: "0 !important",
    paddingLeft: "10px !important",
    paddingTop: "10px !important",
    [theme.breakpoints.up("md")]: {
      borderRadius: 0,
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
      borderRight: 0,
      margin: 0,
      boxShadow: "none",
    },
  },
  selectCenter: {
    backgroundColor: "white !important",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    color: "#535050 !important",
    border: "1px solid #535050",
    height: 60,
    fontFamily: "Martel Sans",
    overflow: "hidden",
    marginBottom: 15,
    marginRight: 8,
    borderRadius: 14,
    marginTop: "0 !important",
    paddingLeft: "10px !important",
    paddingTop: "10px !important",
    [theme.breakpoints.up("md")]: {
      borderRadius: 0,
      borderRight: 0,
      borderLeft: 0,
      margin: 0,
      boxShadow: "none",
    },
  },
  selectRight: {
    backgroundColor: "white",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    color: "#535050 !important",
    height: 60,
    fontFamily: "Martel Sans",
    overflow: "hidden",
    marginLeft: 5,
    marginBottom: 15,
    borderRadius: 14,
    border: "1px solid #535050",
    marginTop: "0 !important",
    paddingLeft: "10px !important",
    paddingTop: "10px !important",
    [theme.breakpoints.up("md")]: {
      borderRadius: 0,
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      borderLeft: 0,
      margin: 0,
      boxShadow: "none",
    },
  },
  selectCalendarPage: {
    backgroundColor: "white",
    color: "#535050 !important",
    height: 60,
    borderRadius: 14,
    fontFamily: "Martel Sans",
    overflow: "hidden",
    marginTop: "0 !important",
    paddingLeft: "10px !important",
    paddingTop: "25px !important",
  },
  selectCalendarPage1: {
    backgroundColor: "white",
    color: "#535050 !important",
    height: 60,
    borderRadius: 14,
    fontFamily: "Martel Sans",
    overflow: "hidden",
    marginTop: "10px !important",
    paddingLeft: "10px !important",
    paddingTop: "25px !important",
  },


}));

const Selector = (props) => {
  const classes = useStyles();

  return (
    <FormControl
      variant='filled'
      className={
        props.home ? classes.formControlHome : classes.formControlCalener
      }
    >
      <InputLabel
        htmlFor='demo-customized-select-native'
        className={classes.label}
      >
        {props.label}
      </InputLabel>

      <NativeSelect
        id='demo-customized-select-native'
        value={props.value}
        onChange={props.handleChange}
        label={props.label}
        disableUnderline={true}
        IconComponent='none'

        className={
          props.position === "left"
            ? classes.selectLeft
            : props.position === "center"
              ? classes.selectCenter
              : props.position === "right"
                ? classes.selectRight
                : props.getCompaniesListApiCallOnCalenderPage ? classes.selectCalendarPage1 : classes.selectCalendarPage
        }
      >
        {props?.options?.map((item) => (
          <option
            className={classes.selectItem}
            key={item.value}
            value={item.value}
          >
            {item.label}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};

export default React.memo(Selector);
