import React, { useState, useEffect } from "react";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { BASE_URL } from "../api/baseurl";
import Axios from "axios";
// import { getLocalStorageData } from "../util/localStorageService";
import BookingDetails from '../components/BookingDetails';
import Grid from '@material-ui/core/Grid';
import { ReactComponent as ArrowIcon } from '../assets/images/Arrow.svg';
import CustomButton from "../components/CustomButton";
import Footer from "../components/Footer";
// import processDuration from '../config/processDuration';
import * as moment from "moment-timezone";
import ReactGA from 'react-ga';
import MetaTitle from "./MetaTitle";

const useStyles = makeStyles((theme) => ({
  fullPage: {
    width: "100vw",
    minHeight: "-webkit-fill-available",
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  restOfPage: {
    width: '90%',
    display: "flex",
    maxWidth: "1100px",
    flexDirection: "column",
    justifyContent: 'center',
  },
  backBtnWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  bookingText: {
    fontFamily: "airbnb-cereal-app",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    marginLeft: '-69px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '-13px'
    },
  },
  cancelBookingButton: {
    width: "70%",
    paddingTop: 10,
    paddingBottom: 10,

    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },

  backToHome: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  }
}));
const Cancelation = (props) => {
  const [isLoading, setisLoading] = useState(false);
  // const [bookingId, setBookingId] = useState("");
  const history = useHistory();
  const classes = useStyles();
  const search = useLocation().search;
  const timezone = new URLSearchParams(search).get('timezone')
  const company = new URLSearchParams(search).get('companyName')
  const service = new URLSearchParams(search).get('serviceName')
  const bookingId = new URLSearchParams(search).get('bookingId')
  const servicePrice = new URLSearchParams(search).get('price')
  const email = new URLSearchParams(search).get('customerEmail')
  const durationTime = new URLSearchParams(search).get('durationInMinutes')
  const location = new URLSearchParams(search).get('location')
  const startSlotTime = new URLSearchParams(search).get('startTime')
  const selectedDate = new URLSearchParams(search).get('date')
  const refundable = new URLSearchParams(search).get('refundable')
  const isAdmin = new URLSearchParams(search).get('isAdmin')

  console.log(durationTime, 'durationTimedurationTime');
  ReactGA.initialize('GTM-WZCJ3Q3');
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])




  const handleCancelationStatus = async () => {
    let status = "";
    let message = "";
    try {
      if (bookingId) {
        const { data } = await Axios.post(`${BASE_URL}/cancelBooking`, {
          bookingId,
          isAdmin: (isAdmin ? false : true),
          isRefundable: (refundable == 0 ? false : true),
        });
        if (data?.error === 0) {
          setisLoading(false);
          status = "success";
          message = `${data?.message}`;
          history.push({
            pathname: "/status",
            search: `?cancelledstate=${status}`,
            state: {
              Error: `${data.error}`,
              Message: `${data.message}`,
              companyLabel: `${company}`,
              branchName: `${location}`,
              bookingTimeSelectedDate: `${moment(selectedDate, ["YYYYMMDD", "DDMMYYYY", "MMMM Do YYYY"]).format("MMMM Do YYYY")}`,
              serviceName: `${service}`,
              bookingTimeStartSlotTime: `${setTimeZone(startSlotTime)}`,
              servicePrice: `${servicePrice}`,
              bookingCode: `${bookingId}`,
              durationTime: `${durationTime}`
            },
          });
        }
      }
    } catch (error) {
      setisLoading(false);
      if (error?.response?.data) {
        const { data } = error?.response;
        if (data?.error === 1) {
          status = "failure";
          message = `${data.message}`;
          history.push({
            pathname: "/status",
            search: `?cancelledstate=${status}`,
            state: {
              Error: `${data.error}`,
              Message: `${data.message}`,
              companyLabel: `${company}`,
              branchName: `${location}`,
              bookingTimeSelectedDate: `${moment(selectedDate, ["YYYYMMDD", "DDMMYYYY", "MMMM Do YYYY"]).format("MMMM Do YYYY")}`,
              serviceName: `${service}`,
              bookingTimeStartSlotTime: `${setTimeZone(startSlotTime)}`,
              servicePrice: `${servicePrice}`,
              bookingCode: `${bookingId}`,
              durationTime: `${durationTime}`
            },
          });
        }
      } else {
        console.log(error?.response);
      }

    };
  }
  function setTimeZone(startSlotTime, timezone = "Asia/dubai") {
    moment.tz.setDefault(timezone);
    let d = moment(startSlotTime, "HH:mm A").format("h:mm A");
    moment.tz.setDefault();
    return d;
  }

  return (
    <>
      <MetaTitle title="Cancel Booking l Wake2Wake" />
      <div className={classes.fullPage}>
        <div className={classes.restOfPage}>
          <div className={classes.backBtnWrapper}>
            <Grid item xs={4} lg={6} md={6}>
              <Grid container className={classes.itemsList}>
                <ArrowIcon />
              </Grid>
            </Grid>
            <Grid item xs={8} lg={6} md={6}>
              <Grid container className={classes.itemsList}>
                <p className={classes.bookingText}>Cancel Booking</p>
              </Grid>
            </Grid>
          </div>

          <div className={classes.bookingDetailsBox}>
            <BookingDetails
              companyLabel={company}
              branchName={location}
              bookingTimeSelectedDate={moment(selectedDate, ["YYYYMMDD", "DDMMYYYY", "MMMM Do YYYY"]).format("MMMM Do YYYY")}
              serviceName={service}
              bookingTimeStartSlotTime={setTimeZone(startSlotTime)}
              servicePrice={servicePrice}
              bookingCode={bookingId}
              durationTime={durationTime}

            />
          </div>

          <div className={classes.backToHome}>
            <CustomButton
              onClick={handleCancelationStatus}
              bookingCancel={true}
            >
              CANCEL BOOKING
            </CustomButton>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );

}
export default Cancelation;