import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core';
import Footer from '../components/Footer';
// import { color } from '@material-ui/system';
import ReactGA from 'react-ga';

const useStyles = makeStyles(theme => ({
    fullPage: {
        width: '100vw',
        minHeight: '-webkit-fill-available',
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
    },
    restOfPage: {
        display: 'flex',
        flex: 1,
        padding: 20,
        minHeight: '100vh',
        alignItems: 'flex-start',
        margin : '0px 10vw',  
        flexDirection: 'column',
    },
    title: {
        fontSize: 36,
        fontWeight: '700',
        marginBottom: 0,
        marginTop: 0,
        textAlign: 'left',
        fontFamily: 'Martel Sans',
        [theme.breakpoints.down('sm')]: {
            fontSize: 24,
        },
    },
    text: {
        textAlign: 'justify',
        fontFamily: 'Martel Sans',
    },
    textheading : {
        fontWeight : 'bold',
        color : '#000'
    }
}));

const AboutUspage = props => {
    ReactGA.initialize('GTM-WZCJ3Q3');
    useEffect(() =>{
        ReactGA.pageview(window.location.pathname + window.location.search);
    },[])

    const classes = useStyles();
    return (
        <div className={classes.fullPage}>
            <div className={classes.restOfPage}>
                <p className={classes.title}>About Us</p>
                <p className={classes.textheading}>Thanks for coming to our About Us page.</p>
                <br />
                
                <p className={classes.text}>
                To tell you a bit more about us, iSKI Center is a complete water-sports hub at the center of the most vibrant city in the Middle East. It is managed by water sports fanatics with a mission to grow the industry.  
                </p>
                <p className={classes.text}>
                Our captains are well trained to drive our boat in a way suitable for Water Ski.
                </p>
                
                <p className={classes.textheading}>See you on the Water</p>
                <br />
                 <p className={classes.text}>  
                    Address: iSKI Center, Deira Island, Dubai, United Arab Emirates
                </p>
                <br />
                 <p className={classes.text}>  
                    Contact Details: iskicenter@gmail.com
                </p>
               

            </div>
            <Footer />
        </div>
    );
}

export default AboutUspage;
