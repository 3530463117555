import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay'

const AutoplaySlider = withAutoplay(AwesomeSlider)
const mock = [
  {
    title: 200,
    subtitle: '200 + strong water skii community.',
    suffix: '+',
  },
  {
    title: 30,
    subtitle: '30 + nationalities fly to Dubai to experience iSKI Center.',
    suffix: '+',
  },
  {
    title: 99,
    subtitle: '99% of our customers were satisfied with our service.',
    suffix: '%',
  },
];

const FeaturesScroll = () => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [viewPortEntered, setViewPortEntered] = useState(false);
  const setViewPortVisibility = (isVisible) => {
    if (viewPortEntered) {
      return;
    }

    setViewPortEntered(isVisible);
  };

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column-reverse'}>
        <Grid item xs={12} md={6} data-aos={isMd ? 'fade-right' : 'fade-up'}>
          <Box marginBottom={4}>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              Join our Community.
            </Typography>
            <Typography component={'p'} color={'text.secondary'}>
              Our community has been growing strong throughout the years. We
              have seen beginners turn to atheletes. If you haven't tried yet,
              give us a call and we'll be sure to welcome you on the water.
            </Typography>
          </Box>
          <Box>
            <Grid container spacing={2}>
              {mock.map((item, i) => (
                <Grid key={i} item xs={12} md={4}>
                  <Typography variant="h4" color={'primary'} gutterBottom>
                    <Box fontWeight={600}>
                      <VisibilitySensor
                        onChange={(isVisible) =>
                          setViewPortVisibility(isVisible)
                        }
                        delayedCall
                      >
                        <CountUp
                          duration={2}
                          end={viewPortEntered ? item.title : 0}
                          start={0}
                          suffix={item.suffix}
                        />
                      </VisibilitySensor>
                    </Box>
                  </Typography>
                  <Typography component="p">{item.subtitle}</Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item container justifyContent={'center'} xs={12} md={6}>
        
        <AutoplaySlider 
        play={true}
        >
            <Box height={1} width={1} maxHeight={800}>
            <Box
              component={LazyLoadImage}
              effect="blur"
              src={
                mode === 'light'
                  ? 'https://firebasestorage.googleapis.com/v0/b/skmd-test-ef758/o/Article_iSKI.png?alt=media&token=bef6a8e9-7692-4728-a4e7-80ab11d500f9'
                  : 'https://firebasestorage.googleapis.com/v0/b/skmd-test-ef758/o/Article_iSKI.png?alt=media&token=bef6a8e9-7692-4728-a4e7-80ab11d500f9'
              }
              height={{ xs: 'auto', md: 1 }}
              maxHeight={{ xs: 300, md: 1 }}
              width={1}
              maxWidth={1}
            />
          </Box>
          <Box height={1} width={1} maxHeight={800}>
            <Box
              component={LazyLoadImage}
              effect="blur"
              src={
                mode === 'light'
                  ? 'https://firebasestorage.googleapis.com/v0/b/skmd-test-ef758/o/Article_iSKI.png?alt=media&token=bef6a8e9-7692-4728-a4e7-80ab11d500f9'
                  : 'https://firebasestorage.googleapis.com/v0/b/skmd-test-ef758/o/Article_iSKI.png?alt=media&token=bef6a8e9-7692-4728-a4e7-80ab11d500f9'
              }
              height={{ xs: 'auto', md: 1 }}
              maxHeight={{ xs: 300, md: 1 }}
              width={1}
              maxWidth={1}
            />
          </Box>
          <Box height={1} width={1} maxHeight={800}>
            <Box
              component={LazyLoadImage}
              effect="blur"
              src={
                mode === 'light'
                  ? 'https://firebasestorage.googleapis.com/v0/b/skmd-test-ef758/o/Article_iSKI.png?alt=media&token=bef6a8e9-7692-4728-a4e7-80ab11d500f9'
                  : 'https://firebasestorage.googleapis.com/v0/b/skmd-test-ef758/o/Article_iSKI.png?alt=media&token=bef6a8e9-7692-4728-a4e7-80ab11d500f9'
              }
              height={{ xs: 'auto', md: 1 }}
              maxHeight={{ xs: 300, md: 1 }}
              width={1}
              maxWidth={1}
            />
          </Box>
          </ AutoplaySlider>
         
        </Grid>
      </Grid>
    </Box>
  );
};

export default FeaturesScroll;
