import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const Toaster = ({ open, handleCloseSnakbar, error, message }) => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSnakbar}>
      <Alert
        onClose={handleCloseSnakbar}
        severity={!error? "success" : "error"}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toaster;
