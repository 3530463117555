import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import CustomButton from '../../../../../CustomButton';
import { NavItem } from './components';
import iskiLogo from '../../../../assets/ISKI LOGO_transparent.png';

const Topbar = ({
  onSidebarOpen,
  pages,
  colorInvert = false,
}) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const {
    landings: landingPages,
    secondary: secondaryPages,
    company: companyPages,
    account: accountPages,
    portfolio: portfolioPages,
    blog: blogPages,
  } = pages;

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
      height = {'100%'}
    >
      <Box
        display={'flex'}
        component="a"
        href="/"
        title="iSKI Center"
        width={{lg:120, xs: 100,}}
        height = {100}
      >
        <Box
          component={'img'}
          src={
            mode === 'light' && !colorInvert
              ? iskiLogo
              : iskiLogo  }
          height={1}
          width={1}
        />
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        <Box marginLeft={4}>
        <CustomButton confirm={true} variant="contained"
               color="primary"
               component="a"
               href="/calendar"
               size="large">
               Book Now
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Topbar;
