import { Button, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  button: {
    height: 60,
    fontSize: 19,
    backgroundColor: "#C4C4C4",
    opacity: 0.9,
    color: "#535050",
    marginLeft: 5,
    fontFamily: "Martel Sans",
    borderRadius: 14,
    overflow: "hidden",
    fontWeight: "700",
    marginBottom: "30px",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginTop: 40,
      width: 240,
    },
  },
  searchButtom: {
    height: 60,
    fontSize: 20,
    fontWeight: "500",
    fontStyle: "normal",
    fontFamily: "airbnb-cereal-app",
    backgroundColor: "#5182FF",
    boxShadow: "0px 10px 40px #D4D9E8",
    color: "white",
    marginLeft: 5,
    borderRadius: "8px",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginTop: 40,
      width: "100%",
    },
  },
  confirm: {
    fontSize: 17,
    fontWeight: "500",
    padding: "3px 23px",
    fontStyle: "normal",
    fontFamily: "airbnb-cereal-app",
    backgroundColor: "#C4C4C4",
    boxShadow: "0px 10px 40px #D4D9E8",
    height: "60px",
    color: "white",
    backgroundColor : "#5182FF",
    marginLeft: 5,
    borderRadius: "8px",
    overflow: "hidden",
    // marginBottom: "30px",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginTop: 0,
      width: "100%",
      fontSize: 18,
      padding: "20px",
    },
    textTransform: "capitalize",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "30px",
    },
  },
  calendar: {
    height: 60,
    backgroundColor: "white",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    color: "#535050 !important",
    fontFamily: "Martel Sans",
    borderRadius: 14,
    border: "1px solid #535050",
    overflow: "hidden",
    fontWeight: "400",
    fontSize: 17,
    marginBottom: 10,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: 170,
    [theme.breakpoints.up("md")]: {
      width: 240,
    },
  },
  bookingCancel: {
    height: 60,
    fontSize: 20,
    fontWeight: "normal",
    fontStyle: "normal",
    fontFamily: "airbnb-cereal-app",
    backgroundColor: "#EB3D3D",
    boxShadow: "0px 10px 40px #D4D9E8",
    color: "white",
    marginLeft: 5,
    borderRadius: "8px",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginTop: 40,
      width: "100%",
    },
  },

  end: {
    height: 60,
    backgroundColor: "#535050 ",
    border: "1px solid #535050",
    color: "white !important",
    fontFamily: "Martel Sans",
    borderRadius: 14,
    overflow: "hidden",
    marginTop: 5,
    width: 240,
    fontWeight: "600",
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      borderRadius: 0,
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      borderLeft: 0,
    },
  },
  disabled: {
    backgroundColor: "#000000AA !important",
    color: "white !important",
    opacity: 0.5,
  },
}));

const CustomButton = (props) => {
  const classes = useStyles();

  return (
    <Button
    href= {props.href}
      disabled={props.disabled}
      variant="contained"
      className={
        props.confirm
          ? classes.confirm
          : props.end
          ? classes.end
          : props.calendar
          ? classes.calendar
          : props.bookingCancel
          ? classes.bookingCancel
          : props.searchButtom
          ? props.searchButtom
          : classes.button
      }
      classes={{ disabled: classes.disabled }}
      onClick={props.onClick}
      startIcon={props.startIcon}
    >
      {props.children}
    </Button>
  );
};

export default CustomButton;
