/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import image1 from "../../assets/Jamal_Waterskii.jpeg"
import  CustomButton from "../../../CustomButton"
import { makeStyles } from '@material-ui/core';
const Hero = ()=> {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const useStyles = makeStyles(() =>({
    searchButtom: {
      height: 45,
      fontSize: "20px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontFamily: "airbnb-cereal-app-light !import",
      textTransform: "capitalize",
      backgroundColor: "#FFFFFF",
      boxShadow: "0px 8px 24px rgba(215, 215, 215, 0.25)",
      color: "#808080",
      marginLeft: 5,
      borderRadius: "8px",
      overflow: "hidden",
      marginLeft: "130px",
      padding: "0px 30px",
      [theme.breakpoints.down("md")]: {
        marginLeft: 0,
        marginTop: 40,
        width: "auto",
        fontSize: "20px",
        fontWeight: "500",
        lineHeight: "140%",
        marginTop: "20px",
        padding: "6px 30px",
        height: "auto",
      },
    },
  }))
  const classes = useStyles();

  return (
    <Grid container spacing={4}>
      <Grid item container alignItems={'center'} xs={12} md={6}>
        <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
          <Box marginBottom={2}>
            <Typography
              variant="h2"
              color={'primary'}
              sx={{
                fontWeight: 700,
              }}
            > A complete Water Sports   Haven at the heart of Dubai{'  '}
             
              <Typography
                component={'span'}
                variant={'inherit'}
                color={'primary'}
                sx={{
                  background: `linear-gradient(180deg, transparent 82%, ${alpha(
                    theme.palette.secondary.main,
                    0.3,
                  )} 0%)`,
                }}
              >
           

              </Typography>
            </Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography
              variant="h6"
              component="p"
              color="text.secondary"
              sx={{ fontWeight: 400 }}
            >
              iSKI Center is a complete water-sports hub at the center of the
              most vibrant city in the Middle East.
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'stretched', sm: 'flex-start' }}
          >
              <CustomButton confirm={true} variant="contained"
               color="primary"
               component="a"
               href="/calendar"
               size="large">
           Book Now
          </CustomButton>
            {/* <Box
              marginTop={{ xs: 2, sm: 0 }}
              marginLeft={{ sm: 2 }}
              width={{ xs: '100%', md: 'auto' }}
            >
              <Button
                component={'a'}
                href={'www.iski.skmdsports.com'}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth={isMd ? false : true}
              >
                View documentation
              </Button>
            </Box> */}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          height={1}
          width={1}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box height={1} width={1} maxWidth={500}>
            <Box
              component={'img'}
              src={image1}
              width={1}
              height={1}
              borderRadius={2}
              sx={{
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Hero;
