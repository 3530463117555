import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';
import getTheme from './theme';
import WebBasic from './WebBasic';

const IskiHomePage=()=>{
    return(<ThemeProvider theme={getTheme('light')}>
    <CssBaseline />
    <Paper elevation={0}><WebBasic/></Paper>
    </ThemeProvider>)
}



export default IskiHomePage;
