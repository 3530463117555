import React, {useState , useEffect} from 'react';
import { Button, CircularProgress, makeStyles, Snackbar, TextField } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Joi from 'joi';
import Footer from '../components/Footer';
import ReactGA from 'react-ga';


const useStyles = makeStyles(theme => ({
    fullPage: {
        width: '100vw',
        height: '100vh',
        minHeight: '-webkit-fill-available',
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'scroll',
    },
    restOfPage: {
        display: 'flex',
        flex: 1,
        padding: 20,
        paddingTop: '12vh',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
    },
    title: {
        fontSize: 36,
        fontWeight: '700',
        marginBottom: 0,
        marginTop: 0,
        textAlign: 'left',
        fontFamily: 'Martel Sans',
        [theme.breakpoints.down('sm')]: {
            fontSize: 24,
        },
    },
    form: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: '100%',

    },
    button: {
        height: 60,
        backgroundColor: '#535050',
        color: 'white !important',
        fontFamily: 'Martel Sans',
        borderRadius: 10,
        overflow: 'hidden',
        fontWeight: '600',
        width: '50%',
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    disabled: {
        backgroundColor: '#000000AA !important',
        color: 'white',
    },
    label: {
        backgroundColor: 'white !important',
        fontFamily: 'Martel Sans',
    },
    textField: {
        backgroundColor: 'white !important',
        border: '1px solid #CCCCCC',
        width: '50%',
        marginTop: 20,
        fontFamily: 'Martel Sans',
        borderRadius: 10,
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

const ContactPage = props => {
    const classes = useStyles();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false);

    ReactGA.initialize('GTM-WZCJ3Q3');
    useEffect(() =>{
        ReactGA.pageview(window.location.pathname + window.location.search);
    },[])

    const disabled = () => {
        try {
            Joi.assert(email, Joi.string().email({ tlds: {allow: false} }));
            return name.length === 0 || message.length === 0;
        } catch (e) {
            return true;
        }
    };

    const submitForm = (ev) => {
        setLoading(true);
        ev.preventDefault();
        const form = ev.target;
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
          if (xhr.readyState !== XMLHttpRequest.DONE) return;
          if (xhr.status === 200) {
            form.reset();
            setEmail('');
            setName('');
            setTitle('');
            setMessage('');
            setSuccess(true);
            setLoading(false);
          } else {
            setLoading(false);
            setFail(true);
          }
        };
        xhr.send(data);
    }

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    return (
        <div className={classes.fullPage}>
            <div className={classes.restOfPage}>
                <p className={classes.title}>Contact Us</p>
                <form action="https://formspree.io/f/mgepraqd" method="POST" onSubmit={submitForm} className={classes.form}>
                    <TextField
                        InputProps={{ className: classes.label, disableUnderline: true }}
                        InputLabelProps={{ className: classes.label }}
                        className={classes.textField}
                        label="Name"
                        variant="filled"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        name="Name"
                    />
                    <TextField
                        InputProps={{ className: classes.label, disableUnderline: true }}
                        InputLabelProps={{ className: classes.label }}
                        className={classes.textField}
                        label="Email"
                        variant="filled"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        name="Email"
                    />
                    <TextField
                        InputProps={{ className: classes.label, disableUnderline: true }}
                        InputLabelProps={{ className: classes.label }}
                        className={classes.textField}
                        label="Title"
                        variant="filled"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        name="Title"
                    />
                    <TextField
                        InputProps={{ className: classes.label, disableUnderline: true }}
                        InputLabelProps={{ className: classes.label }}
                        className={classes.textField}
                        label="Message"
                        variant="filled"
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                        name="Message"
                        multiline={true}
                        rows={5}
                    />
                    <Button disabled={disabled()} variant="contained" className={classes.button} classes={{disabled: classes.disabled}} type="submit">
                        {!loading ? 'Send' : <CircularProgress />}
                    </Button>
                </form>
                <Snackbar open={success} autoHideDuration={3000} onClose={() => setSuccess(false)}>
                    <Alert onClose={() => setSuccess(false)} severity="success">
                        Your message has been sent!
                    </Alert>
                </Snackbar>
                <Snackbar open={fail} autoHideDuration={3000} onClose={() => setFail(false)}>
                    <Alert onClose={() => setFail(false)} severity="error">
                        An error occurred while sending your message! Please try again later.
                    </Alert>
                </Snackbar>
            </div>
            <Footer />
        </div>
    );
}

export default ContactPage;