import { makeStyles } from "@material-ui/core";
import React,{useEffect} from "react";
import Footer from "../components/Footer";
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
const useStyles = makeStyles((theme) => ({
  fullPage: {
    width: "100vw",
    minHeight: "-webkit-fill-available",
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "column",
  },
  restOfPage: {
    display: "flex",
    flex: 1,
    minHeight: "100vh",
    padding: 20,
    margin: '0px 10vw',
    alignItems: "flex-start",
    flexDirection: "column",
  },
  refundHeading: {
    fontSize: 36,
    fontWeight: 'normal',
    marginBottom: 20,
  },
  title: {
    fontSize: 16,
    fontWeight: "700",
    marginBottom: 0,
    marginTop: 0,
    textAlign: "left",
    fontFamily: "Martel Sans",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  text: {
    textAlign: "justify",
    fontFamily: "Martel Sans",
  },
  backToHome: {
    width: "70%",
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    justifyContent: 'start',
    marginTop: '30px',
    flex: 1,
    margin : 'auto',
    margin: '0px 10vw',

    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: '0px 20px 0px 20px',
      justifyContent: 'center',
    },
  },
}));

const RefundPolicyPage = (props) => {
  const classes = useStyles();
  ReactGA.initialize('GTM-WZCJ3Q3');

 useEffect(() =>{
  window.scrollTo(0, 0);
  ReactGA.pageview(window.location.pathname + window.location.search);

 },[])
  return (
    <div className={classes.fullPage}>
      <div className={classes.restOfPage}>
        <div className={classes.refundHeading}>Refund Policy</div>
        <div className={classes.title}>DELIVERY/ SHIPPING POLICY</div>
        <ul>
          <li className={classes.text}>Customer will receive an instant payment notification by email once the payment is debited from their card</li>
          <li className={classes.text}>Customer will claim their ride with I LOVE SKI WATER SPORTS based on their request</li>
        </ul>
        <div className={classes.title}>Multiple Shipment</div>
        <div className={classes.text}>Customer will receive an instant payment notification by email once the payment is debited from their card</div>
        <div className={classes.title}>Refund Policy</div>
        <p className={classes.text}>You may request to cancel your booking up to 48 hours prior to the time of booking. If the service starts in less than 48 hours, the booking cannot be canceled and the refund policy applies.</p>
        <p className={classes.text}>Refunds will be done only through the Original Mode of Payment and will be processed within 10 to 45 days depending on the issuing bank of the paying card.</p>
        <div className={classes.title}>For No Refund Policy</div>
        <p className={classes.text}>In the event  of</p>
        <ul>
          <li>NO SHOW</li>
          <li>Short notice cancellation ( less than 24 hours )</li>
        </ul>
        <p className={classes.text}>I LOVE SKI WATER SPORTS reserves the right not to refund the paid amount.</p>

        <p className={classes.title}>Contact Us</p>
        <ul>
          <li className={classes.text}>iSKI Center, Palm Deira, Dubai, United Arab Emirates</li>
          <li className={classes.text}>iskicenter@gmail.com</li>
        </ul>

      </div>
   
      <Footer />
    </div>
  );
};

export default withRouter(RefundPolicyPage);
