import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import BookingDetails from './BookingDetails';
import { getLocalStorageData } from "../util/localStorageService";
import Grid from '@material-ui/core/Grid';
import { ReactComponent as ArrowIcon } from '../assets/images/Arrow.svg';
import CustomButton from "./CustomButton";
import Footer from "./Footer";
import processDuration from '../config/processDuration';
import DOMPurify from 'dompurify';
// import Parser from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
  fullPage: {
    width: "100vw",
    minHeight: "-webkit-fill-available",
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  restOfPage: {
    width: "90%",
    maxWidth: "1100px",
    display: "flex",
    flex: 1,
    padding: '20px 0px',
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  
  },
  title2: {
    fontSize: 24,
    fontWeight: 'normal',
    fontFamily: "airbnb-cereal-app",
    color: '#080133',
    textAlign: 'center'
  },
  backBtnWrapper: {
   width : '90%',
    display: 'flex',
    alignItems: 'center'
  },
  bookingText :{
    fontFamily: "airbnb-cereal-app",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    marginLeft: '-69px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '-13px'
    },
  },
  bookingDetailsBox : {
    
  },
  backToHome: {
    width: "70%",
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    justifyContent : 'center',
    marginTop : '30px',
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  confirmMessage: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '70vw',
  },
  confirmMessageText: {
    textAlign: 'center',
    padding: '0px 12px',
    fontSize: 19,
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#808080',
    fontFamily: 'airbnb-cereal-app-light',
    margin: '0px 0px',
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
}));
const CancellationStatusDetailsPage = (props) => {
  const classes = useStyles();
  const history = useHistory();

    const handleBacktoHome = () => {
      history.push("/");
    };
  return (
    
    <div className={classes.fullPage}>
      <div className={classes.restOfPage}>
        <div className={classes.backBtnWrapper}>
          <Grid item xs={4} lg={6} md={6}>
            <Grid container className={classes.itemsList}>
              <ArrowIcon onClick={handleBacktoHome} />
            </Grid>
          </Grid>
          <Grid item xs={8} lg={6} md={6}>
            <Grid container className={classes.itemsList}>
              <p className={classes.bookingText}>Cancel Booking</p>
            </Grid>
          </Grid>
        </div>
        {props.bookingStatusHeading == 'Booking NOT Cancelled' ? null: <p className={classes.title2}>{props.bookingStatusHeading}</p> }
        <div className={classes.confirmMessage}>
          <p className={classes.confirmMessageText} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.bookingStatusPara)}}></p>
     
        </div>
        <div className={classes.bookingDetailsBox}>
        {props.bookingStatusHeading == 'Booking Cancelled' ?
          <BookingDetails
            companyLabel={props.state.companyLabel}
            branchName={props.state.branchName}
            bookingTimeSelectedDate={props.state.bookingTimeSelectedDate}
            serviceName={props.state.serviceName}
            bookingTimeStartSlotTime={props.state.bookingTimeStartSlotTime}
            servicePrice={props.state.servicePrice}
            bookingCode={props.state.bookingCode}
            durationTime={props.state.durationTime}
          /> : null }
        </div>
        <div className={classes.backToHome}>
          <CustomButton
            onClick={handleBacktoHome}
            confirm={true}
          >
            back to home
          </CustomButton>
        </div>
      </div>
        <Footer />
    </div>
  );
};

export default CancellationStatusDetailsPage;
