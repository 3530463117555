import { makeStyles, TextField,OutlinedInput } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
    textField: {
        backgroundColor: 'white !important',
        border: '1px solid #CCCCCC',
        marginRight: 20,
        width: 240,
        fontFamily: 'airbnb-cereal-app',
        borderRadius: 10,
        overflow: 'hidden'
    },
    textFieldLeft: {
        border: '1px solid #D1D5DB',
        boxSizing : 'border-box',
        borderRadius : '6px',
        width: '95%',
        height:'100%',
        fontFamily: 'airbnb-cereal-app',
        overflow: 'hidden',
        marginTop : '10px'
    },
    textFieldRight: {
        border: '1px solid #D1D5DB',
        boxSizing : 'border-box',
        borderRadius : '6px',
        width: '95%',
        height:'100%',
        fontFamily: 'airbnb-cereal-app',
        overflow: 'hidden',
        marginTop : '10px'
    },

    textFieldBottomLeft: {
        backgroundColor: 'white !important',
        border: '1px solid #CCCCCC',
        width: 240,
        fontFamily: 'Martel Sans',
        borderRadius: 10,
        overflow: 'hidden',
        marginBottom:10,
        [theme.breakpoints.up('sm')]: {
           height:'100%',
            width:'50%',
            borderRadius: 0,
            border: 0,
        },
        [theme.breakpoints.down('sm')]: {
            height:'100%',
            width:'50%',
            borderRadius: 0,
            border: 0,
        },
    },
    textFieldBottomRight: {
        backgroundColor: 'white !important',
        border: '1px solid #CCCCCC',
        width: 240,
        fontFamily: 'Martel Sans',
        borderRadius: 10,
        overflow: 'hidden',
        marginBottom: 10,
        [theme.breakpoints.up('sm')]: {
            height:'100%',
            width:'49.1%',
            borderRadius: 0,
            border:0,
            borderLeft:' 1px solid #000000'
        },
        [theme.breakpoints.down('sm')]: {
            height:'100%',
            width:'49.1%',
            borderRadius: 0,
            border:0,
            borderLeft:' 1px solid #000000'
        },
    },
    label: {
        backgroundColor: 'red !important',
        fontFamily: 'Martel Sans',
    },
    InputLabel : {
        fontSize : '18px',
        fontFamily: 'airbnb-cereal-app',
        fontStyle : 'normal',
        fontWeight : '500',
        paddingLeft : '10px'
    },
    inputFeildList : {
        margin : '15px 0px',
    },
    span : {
      color : '#5182FF',
      paddingLeft : '3px'     
    }
}));

const CustomTextField = props => {
    const classes = useStyles();

    const positionToClass = (position) => {
        switch(position) {
            case 'left': return classes.textFieldLeft;
            case 'right': return classes.textFieldRight;
            case 'topLeft': return classes.textFieldTopLeft;
            case 'topRight': return classes.textFieldTopRight;
            case 'bottomLeft': return classes.textFieldBottomLeft;
            case 'bottomRight': return classes.textFieldBottomRight;
            default: return classes.textField;
        }
    }
    
    return (
        <div className={classes.inputFeildList}>
        <div className={classes.InputLabel} >{props.label}<span className={classes.span}>*</span></div>
        <OutlinedInput
            InputProps={{ className: classes.label, disableUnderline: true }}
            InputLabelProps={{ className: classes.label }}
            className={positionToClass(props.position)}
            placeholder={props.label}
            onChange={props.onChange}
            value={props.value}
            
        />
        </div>
    );
}

export default CustomTextField;