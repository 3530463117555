import React, { useState, useEffect } from 'react';
import { makeStyles, TextField, OutlinedInput } from '@material-ui/core';
import PhoneInput from 'react-phone-number-input';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en.json'
import { letterSpacing } from '@material-ui/system';
import { keys } from '@material-ui/core/styles/createBreakpoints';

const useStyles = makeStyles(theme => ({
    InputLabel: {
        fontSize: '18px',
        fontFamily: 'airbnb-cereal-app',
        fontStyle: 'normal',
        fontWeight: '500',
        paddingLeft: '10px',
        marginBottom: '10px'
    },
    inputFeildList: {
        margin: '15px 0px',
    },
    span: {
        color: '#5182FF',
        paddingLeft: '3px'
    },
    phoneBox: {
        display: 'flex',
        flexWrap: 'nowrap'
    },
    selectCountry: {
        border: '2px solid #D1D5DB',
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        width: '25%',
        borderRadius: '6px',
        justifyContent: 'space-around',
        height: '52px',
        [theme.breakpoints.down('sm')]: {
            width: '27%',
        }
    },
    selectCountyFlag: {
        padding: '15px 10px',
        height: '20px',
        width: '20px',
        [theme.breakpoints.down("sm")]: {
            padding: '0px 0px',
            height: '15px',
            width: '15px',
        },
    },
    countryCode: {
        padddingBottom: "20px",
        color: '#171717',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px'
        }
    },
    telephoneNumber: {
        borderRadius: '6px',
        border: '2px solid #D1D5DB',
        marginLeft: '10px',
        width: "65%",
        color: '#000',
        fontSize: '16px',
        fontWeight: 'bold',
        paddingLeft: "10px",
        outline: 'none',
        letterSpacing: '1px',
        [theme.breakpoints.down('sm')]: {
            width: "52%"
        }
    },
    selectOption: {
        width: '20px',
        margin: '10px',
        background: 'none',
        outline: 'none',
        border: 'none',
        [theme.breakpoints.down('md')]: {
            width: '13px',
            margin: '0px',
            outline: 'none',
            border: 'none',
        }
    }

}));

const CountrySelect = ({ value, onChange, labels, ...rest }) => {
    const classes = useStyles()

    return <select
        {...rest}
        value={value}
        className={classes.selectOption}
        onChange={event => onChange(event)}>
        <option value="">
            {labels['ZZ']}
        </option>
        {getCountries().map((country) => (
            <option key={country} value={country} >
                {labels[country]}
            </option>
        ))}
    </select>
}

const PhoneNumberField = props => {
    const classes = useStyles();
   
    

    return (
        <div className={classes.inputFeildList}>
            <div className={classes.InputLabel} >Phone Number<span className={classes.span}>*</span></div>
            <div className={classes.phoneBox}>
                <div className={classes.selectCountry}  >
                    <img height="30px" width="30px" className={classes.selectCountyFlag} src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${props.country}.svg`} />
                    <span className={classes.countryCode}>+ {getCountryCallingCode(props.country)}</span>
                    <CountrySelect
                        labels={en}
                        value={props.country}
                        onChange={(event) => props.onChangeCountry(event)}
                    />
                </div>
                <input type="text" className={classes.telephoneNumber} onChange={(e) => props.handlePhoneNumber(e)} />
            </div>
        </div>
    );
}

export default PhoneNumberField;