import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  selected: {
    width: 90,
    padding: "5px 10px",
    cursor: "pointer",
    margin: 5,
    background: "#5182FF",
    boxShadow: "0px 10px 40px #D4D9E8",
    borderRadius: 12,
    [theme.breakpoints.down("xs")]: {
      padding: "5px 0px",
      width: 90,
    },
  },
  unSelected: {
    borderRadius: 10,
    width: 90,
    padding: "5px 10px",
    cursor: "pointer",
    marginRight: 10,
    [theme.breakpoints.down("xs")]: {
      padding: "5px 0px",
      width: 75,
    },
  },

  text: {
    fontFamily: "airbnb-cereal-app",
    color: "#FFFFFF",
    fontWeight: "normal",
    fontStyle: "normal",
    fontSize: 14,
    textAlign: "center",
    margin: 0,
    lineHeight: "25px",
  },
  priceText: {
    fontFamily: "airbnb-cereal-app-light",
    color: "#FFFFFF",
    textAlign: "center",
    fontWeight: "normal",
    fontStyle: "normal",
    margin: 0,
    fontSize: 12,
    lineHeight: "25px",
  },
  unSelectedText: {
    fontFamily: "airbnb-cereal-app",
    color: "#808080",
    fontWeight: "normal",
    fontStyle: "normal",
    fontSize: 14,
    textAlign: "center",
    margin: 0,
    lineHeight: "25px",
  },
  unSelectPriceTex: {
    fontFamily: "airbnb-cereal-app-light",
    color: "#808080",
    textAlign: "center",
    fontWeight: "normal",
    fontStyle: "normal",
    margin: 0,
    fontSize: 12,
    lineHeight: "25px",
  },
}));

const DurationChip = (props) => {
  const classes = useStyles();
  return (
    <div
      className={props.selected ? classes.selected : classes.unSelected}
      onClick={props.onClick}
    >
      <p className={props.selected ? classes.text : classes.unSelectedText}>
        {`${props.durationLabel}`}
      </p>
      {props.durationPrice && (
        <p
          className={
            props.selected ? classes.priceText : classes.unSelectPriceTex
          }
        >
          {props.durationPrice}
        </p>
      )}
    </div>
  );
};

export default DurationChip;
