import { makeStyles } from "@material-ui/core";
import React,{useEffect} from "react";
import Footer from "../components/Footer";
import { Terms, comercialUse, extremeWake, termsDetails } from "../constant/terms";
import ReactGA from 'react-ga';

const useStyles = makeStyles((theme) => ({
  fullPage: {
    width: "100vw",
    minHeight: "-webkit-fill-available",
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "column",
  },
  restOfPage: {
    display: "flex",
    flex: 1,
    padding: 20,
    minHeight: "100vh",
    alignItems: "flex-start",
    flexDirection: "column",
    margin: '0px 10vw',
  },
  title: {
    fontSize: 36,
    fontWeight: "normal",
    marginBottom: 0,
    marginTop: 0,
    textAlign: "left",
    fontFamily: "Martel Sans",
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
  },
  text: {
    textAlign: "justify",
    fontFamily: "Martel Sans",
  },
  policyList: {
    display: 'flex',

  },
  listNumber: {
    margin: '20px 10px'
  }
}));

const TermsPage = (props) => {
  const classes = useStyles();
  ReactGA.initialize('GTM-WZCJ3Q3');

  useEffect(() =>{
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);

   },[])
  return (
    <div className={classes.fullPage}>
      <div className={classes.restOfPage}>
        <p className={classes.title}>Terms & Conditions</p>
        {extremeWake.map(val => (
          <p className={classes.text}>{val}</p>
        ))}
        {Terms.map(val => (
          <div className={classes.policyList}><p className={classes.listNumber}>{val.count}</p><p className={classes.text} >{val.titles}</p></div>
        ))}
        {comercialUse.map(val => (
          <p className={classes.text}>{val}</p>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default TermsPage;
