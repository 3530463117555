import React, { useEffect, useState } from "react";
import { Checkbox, CircularProgress, makeStyles } from "@material-ui/core";
import Joi from "joi";
import * as moment from "moment";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import CustomButton from "../components/CustomButton";
import CustomTextField from "../components/CustomTextField";
import Footer from "../components/Footer";
// import frontend from "../config/skmdsports";
import {
  getLocalStorageData,
  setLocalStorageData,
} from "../util/localStorageService";
import { BASE_URL } from "../api/baseurl";
import Toaster from "../components/Toaster";
import Grid from "@material-ui/core/Grid";
import { ReactComponent as ArrowIcon } from "../assets/images/Arrow.svg";
// import { textAlign } from "@material-ui/system";
import PhoneNumberField from "../components/PhoneNumberFeild";
import BookingDetails from "../components/BookingDetails";
// import processDuration from "../config/processDuration";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import ReactGA from "react-ga";
import MetaTitle from "./MetaTitle";
import { createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  fullPage: {
    width: "100vw",
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    // overflow: "scroll",
    [theme.breakpoints.up("lg")]: {
      // height: "100vh",
    },
  },
  restOfPage: {
    width: "95%",
    maxWidth: "1100px",
    padding: "10px 0px",
    minHeight: "46vh",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  info: {
    background: "#FFFFFF",
    boxShadow: "0px 6px 40px rgba(208, 208, 208, 0.2)",
    borderRadius: "16px",
    padding: "10px 0",
  },
  title2: {
    fontSize: 24,
    fontWeight: "normal",
    fontFamily: "airbnb-cereal-app",
    color: "#080133",
    textAlign: "center",
  },
  orderDetail: {
    margin: 0,
    marginLeft: 10,
    marginTop: -2,
    marginBottom: 6,
    fontWeight: "normal",
    fontSize: "16px",
    fontStyle: "normal",
    fontFamily: "airbnb-cereal-app",
    color: "#808080",
  },
  orderDetailLabel: {
    marginLeft: 10,
    fontFamily: "airbnb-cereal-app",
    fontSize: 18,
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#080133",
  },
  textDiv: {
    padding: "10px 0px 30px 20px",
    background: "#FFFFFF",
    boxShadow: "0px 6px 40px rgba(208, 208, 208, 0.2);",
    borderRadius: "16px",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  subtitle: {
    fontSize: 24,
    fontWeight: "normal",
    fontStyle: "normal",
    fontFamily: "airbnb-cereal-app",
    color: "#080133",
    textAlign: "center",
    margin: "80px 0px",
  },
  formContainer: {
    padding: 20,
    [theme.breakpoints.up("sm")]: {
      flex: 1,
      height: `calc(100% - 44px)`,
    },
  },
  image: {
    height: `calc(100% - 4px)`,
    border: "1px solid #CCCCCC",
    borderRadius: 10,
    borderLeft: 0,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    maxWidth: "40%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  helperText: {
    fontSize: 10,
    marginTop: 0,
    marginBottom: 10,
    fontFamily: "Martel Sans",
  },
  termsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: "26px",
  },
  termsText: {
    fontSize: 14,
    color: "#808080",
    fontStyle: "normal",
    fontWeight: 400,
    fontFamily: "airbnb-cereal-app-light",
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px",
    },
  },
  link: {
    fontWeight: "normal",
    fontFamily: "airbnb-cereal-app",
    "& > a": {
      color: "#808080",
      textDecoration: "none",
    },
  },
  loading: {
    color: "#535050",
    marginTop: 15,
    marginLeft: 15,
  },
  icon: {
    border: "1px solid #535050",
    borderRadius: 10,
    fontSize: 34,
    fontWeight: "600",
  },
  backBtnWrapper: {
    cursor: "pointer",
    alignSelf: "baseline",
    display: "flex",
    alignItems: "center",
  },
  itemsList: {
    PaddingRight: "30px",
  },
  bookingText: {
    fontFamily: "airbnb-cereal-app",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    [theme.breakpoints.up("xs")]: {
      marginLeft: "-25px",
    },
  },
  root: {
    flexGrow: 1,
  },
  phone_field: {
    display: "flex",
    width: "40%",
    "& > div": {
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "flex-end",
      width: "60%",
      "& > select": {
        width: "20%",
        background: "none",
        border: "none",
      },
      "& > div": {
        width: "20%",
      },
    },
    "& > input": {
      width: "40%",
    },
  },
  paymentConfirmation: {
    width: "30%",

    [theme.breakpoints.down("md")]: {
      marginTop: "15px",
      width: "60%",

      " & .makeStyles-confirm-55": {
        fontSize: "20px",
        height: "60px",
      },
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      " & .makeStyles-confirm-55": {
        fontSize: "17px",
        height: "60px",
      },
    },
  },
}));

const CheckoutPage = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [serviceName, setServiceName] = useState("");

  // console.log(serviceName,'asdfasdfasdfasdf');
  const [servicePrice, setServicePrice] = useState("");
  const [serviceDurationId, setServiceDurationId] = useState("");
  const [country, setCountry] = useState("AE");
  const [bookingTime, setBookingTime] = useState({
    startTime: "",
    endTime: "",
    selectedDate: "",
    time: "",
    slottime: "",
    startSlotTime: "",
    durationTime: "",
  });
  const [errorData, setErrorData] = useState({
    error: false,
    message: "",
    open: false,
  });

  const classes = useStyles();
  const history = useHistory();
  ReactGA.initialize("GTM-WZCJ3Q3");

  useEffect(() => {
    const getCompanyName = getLocalStorageData("selectedCompany");
    const getBranchName = getLocalStorageData("selectedLocation");

    const getServiceName = getLocalStorageData("selectedService");

    const getServicePrice = getLocalStorageData("servicePrice");
    const getServiceDurationId = getLocalStorageData("serviceId");
    const getTimeData = getLocalStorageData("durationTime");
    const getStartSlotTime = getLocalStorageData("bookingtime");
    const getEndSlotTime = moment(getStartSlotTime, "HH:mm A")
      .add(getTimeData, "minutes")
      .format("h:mm A");
    const getSelectedDate = getLocalStorageData("selectedDate");
    const formattedSelectedDate =
      moment(getSelectedDate).format("dddd, MMMM DD");
    const formattedFirstdate = moment(getStartSlotTime, "h:mm A")
      .format("HH:mm")
      .split(":")
      .join("");
    const formatedSeconddate = moment(getEndSlotTime, "h:mm A")
      .format("HH:mm")
      .split(":")
      .join("");

    if (
      getCompanyName &&
      getBranchName &&
      getServiceName &&
      getServicePrice &&
      getServiceDurationId &&
      getStartSlotTime &&
      getEndSlotTime &&
      getTimeData
    ) {
      setCompanyName(getCompanyName);
      setBranchName(getBranchName);
      setServiceName(getServiceName);
      setServicePrice(getServicePrice);
      setServiceDurationId(getServiceDurationId);
      setBookingTime({
        ...bookingTime,
        startTime: formattedFirstdate,
        endTime: formatedSeconddate,
        slottime: `${getStartSlotTime}-${getEndSlotTime}`,
        startSlotTime: getStartSlotTime,
        selectedDate: formattedSelectedDate,
        durationTime: getTimeData,
      });
      setLocalStorageData("bookingDetails", {
        startTime: formattedFirstdate,
        endTime: formatedSeconddate,
        slottime: `${getStartSlotTime}-${getEndSlotTime}`,
        selectedDate: formattedSelectedDate,
        startSlotTime: getStartSlotTime,
      });
    }
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const disabled = () => {
    try {
      Joi.assert(email, Joi.string().email({ tlds: { allow: false } }));
      return (
        !checked ||
        firstName.length === 0 ||
        lastName.length === 0 ||
        email.length === 0
      );
    } catch (e) {
      return true;
    }
  };

  const confirmBooking = async () => {
    setLoading(true);
    const getDate = getLocalStorageData("selectedDate");
    const formattedDate = moment(getDate).format("DDMMYYYY");
    const phoneWithCountryCode = `+${getCountryCallingCode(
      country
    )} ${phoneNumber}`;
    try {
      const { data } = await Axios.post(`${BASE_URL}/addBooking`, {
        customerId: "Jf0Y6JkNg1CNcIq9onjo",
        serviceId: serviceName.value,
        serviceDurationId: serviceDurationId,
        date: formattedDate,
        startTime: bookingTime.startTime,
        endTime: bookingTime.endTime,
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phoneWithCountryCode,
      });
      if (data.error === 0) {
        const { id } = data?.data;
        const customerDetails = {
          firstName,
          lastName,
          email,
          id,
        };
        console.log(data.data.paymentUrl, "URL");
        setLocalStorageData("customerDetails", customerDetails);
        if (data?.data?.paymentUrl) {
          window.location.href = data.data.paymentUrl;
        }
      } else if (data.error === 1) {
        setLoading(false);
        setErrorData({
          ...errorData,
          error: true,
          message: data.message,
          open: true,
        });
      }
    } catch (error) {
      setLoading(false);
      setErrorData({
        ...errorData,
        error: true,
        message: error?.response?.data?.message,
        open: true,
      });
    }
  };

  const openPrivacy = () => {
    window.open("/privacy", "_blank");
  };

  const handleBack = () => {
    history.push("/calendar");
  };

  const handleCloseSnakbar = () => {
    setErrorData({ error: false, message: "", open: false });
  };

  const handlePhoneNumber = (code) => {
    setPhoneNumber(code.target.value);
  };
  const onChangeCountry = (event) => {
    setCountry(event.target.value);
  };

  return (
    <>
      <MetaTitle title="iSKI - Booking Details" />
      <Toaster
        open={errorData.open}
        error={errorData.error}
        message={errorData.message}
        handleCloseSnakbar={handleCloseSnakbar}
      />
      <div className={classes.fullPage}>
        <div className={classes.restOfPage}>
          {loading ? (
            <CircularProgress
              style={{ position: "absolute", left: "40%", top: "20%" }}
              className={classes.loading}
              size={50}
            />
          ) : (
            <div className={classes.formContainer}>
              <div className={classes.backBtnWrapper}>
                <Grid item xs={4} lg={6} md={6}>
                  <Grid container className={classes.itemsList}>
                    <ArrowIcon onClick={handleBack} />
                  </Grid>
                </Grid>
                <Grid item xs={8} lg={6} md={6}>
                  <Grid container className={classes.itemsList}>
                    <p className={classes.bookingText}>Confirm Booking</p>
                  </Grid>
                </Grid>
              </div>
              <BookingDetails
                companyLabel={companyName.label}
                branchName={branchName.label}
                bookingTimeSelectedDate={bookingTime.selectedDate}
                serviceName={serviceName.label}
                bookingTimeStartSlotTime={bookingTime.startSlotTime}
                servicePrice={servicePrice}
                slottime={bookingTime.slottime}
                durationTime={bookingTime.durationTime + " " + "Mins"}
              />
              <p className={classes.subtitle}>Rider Information</p>
              <div className={classes.textDiv}>
                <Grid container className={classes.root} spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <Grid>
                      <CustomTextField
                        position="left"
                        label="First Name"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid>
                      <CustomTextField
                        position="right"
                        label="Last Name"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid>
                      <CustomTextField
                        position="left"
                        label="Email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid>
                      <PhoneNumberField
                        placeholder="Enter phone number"
                        handlePhoneNumber={(code) => handlePhoneNumber(code)}
                        onChangeCountry={(event) => onChangeCountry(event)}
                        country={country}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Grid>
                      <div className={classes.termsContainer}>
                        <Checkbox
                          value={checked}
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                          onChange={(e) => setChecked(e.target.checked)}
                        />
                        <div className={classes.termsText}>
                          I agree to the{" "}
                          <span className={classes.link}>
                            <a href="/privacy" target="_blank">
                              Privacy Policy
                            </a>{" "}
                            ,{" "}
                            <a href="/terms" target="_blank">
                              Terms & Conditions
                            </a>
                            ,<span className={classes.termsText}> and</span>{" "}
                            <a href="/refund" target="_blank">
                              Refund Policy
                            </a>{" "}
                            <span className={classes.termsText}>
                              {" "}
                              ( Bookings can only be refunded 24 hours before
                              the booking time) .
                            </span>
                          </span>{" "}
                        </div>
                      </div>
                      <div className={classes.paymentConfirmation}>
                        <CustomButton
                          onClick={confirmBooking}
                          disabled={disabled()}
                          confirm
                        >
                          Proceed To Payment
                        </CustomButton>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CheckoutPage;
