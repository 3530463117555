import React, {useEffect} from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import Footer from "../components/Footer";
import CustomButton from "../components/CustomButton";
import { getLocalStorageData } from "../util/localStorageService";
import { ReactComponent as ThankIcon } from '../assets/images/ThankIcon.svg';
import BookingDetails from '../components/BookingDetails';
import processDuration from '../config/processDuration';
import * as moment from "moment-timezone";
import { ReactComponent as CalendarIcon } from '../assets/images/CalendarIcon.svg';
import ReactGA from 'react-ga';
import MetaTitle from "./MetaTitle";

const useStyles = makeStyles((theme) => ({
  fullPage: {
    width: "100vw",
    minHeight: "-webkit-fill-available",
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  restOfPage: {
    width: "95%",
    maxWidth: "1100px",
    display: "flex",
    flex: 1,
    padding: '20px 0px',
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  title2: {
    lineHeight: 0,
    width: "70%",
    textAlign: "center",
    fontSize: 30,
    fontWeight: 'normal',
    fontFamily: 'airbnb-cereal-app',
    color: '#080133',
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  imageHeading: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '30px',
    padding: '20px',
    marginTop: '40px',
    marginBottom: '70px'
  },
  map: {
    width: "70%",
    height: "50vh",
    borderRadius: 10,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      height: "242px",
      border: "1px solid #535050",
      filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
      borderRadius: "14px",
    },
  },
  backToHome: {
    width: "70%",
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 30,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  confirmMessage: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '70vw',
  },
  confirmMessageText: {
    textAlign: 'center',
    padding: '0px 12px',
    fontSize: 19,
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#808080',
    fontFamily: 'airbnb-cereal-app-light',
    margin: '0px 0px 20px 0px',
  },
  confirmationCode: {
    textAlign: 'center',
    padding: '0px 10px',
    fontSize: 19,
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#5182FF',
    fontFamily: 'airbnb-cereal-app-light',
    margin: '5px 0px',
  },
  confirmEmail: {
    textAlign: 'center',
    padding: '0px 10px',
    fontSize: 19,
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#080133',
    fontFamily: 'airbnb-cereal-app',
    margin: '20px 0px',
  },
  bookingDetailsBox: {
    marginTop: '60px',
    marginBottom: '30px'
  },
  addCalender: {

  },
  addCalendarButton: {
    backgroundColor: '#5182FF',
    border: 'none',
    boxShadow: '0px 10px 40px #D4D9E8',
    borderRadius: '8px',
    fontSize: '20px',
    fontFamily: 'airbnb-cereal-app',
    fontWeight: 'normal',
    color: '#fff',
    padding: '5px 0px',
    cursor : 'pointer'
  },
  addCalendarContent: {
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addCalenderText :{
    padding : '0 5px'
  }
}));

const ConfirmationPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const search = useLocation().search;
  const timezone = new URLSearchParams(search).get('timezone')
  const company = new URLSearchParams(search).get('companyName')
  const service = new URLSearchParams(search).get('serviceName')
   console.log(service,'hjkkj');

  const bookingId = new URLSearchParams(search).get('bookingId')
  const servicePrice = new URLSearchParams(search).get('price')
  const email = new URLSearchParams(search).get('customerEmail')
  const durationTime = new URLSearchParams(search).get('durationInMinutes')
  const location = new URLSearchParams(search).get('location')
  const startSlotTime = new URLSearchParams(search).get('startTime')
  const selectedDate = new URLSearchParams(search).get('date')
  ReactGA.initialize('GTM-WZCJ3Q3');
  useEffect(() =>{
      ReactGA.pageview(window.location.pathname + window.location.search);
  },[])


  const handleAddToCalendar = () => {
    window.open(`${`https://calendar.google.com/calendar/u/0/r/eventedit?text=${company}&dates=${selectedDate}T${startSlotTime}00/${selectedDate}T${moment(startSlotTime, "HH:mm").add({ minute: durationTime }).format("HHmm")}00&details=Event+Details+Here&location=${location}`}`, '_blank')

  }

  const handleBacktoHome = () => {
    history.push("/");
  };

  function setTimeZone(startSlotTime, timezone = "Asia/dubai") {
    moment.tz.setDefault(timezone);
    let d = moment(startSlotTime, "HH:mm A").format("h:mm A");
    moment.tz.setDefault();
    return d;
  }

  return (
    <>
     <MetaTitle title="Booking Confirmation l Wake2Wake" />
    <div className={classes.fullPage}>
      <div className={classes.restOfPage}>
        <p className={classes.title2}>Get Ready to Ride</p>
        <div className={classes.imageHeading}>
          <ThankIcon />
        </div>
        <div className={classes.confirmMessage}>
          <p className={classes.confirmMessageText}>Payment received and your booking is confimed. We've sent all the details to your email at : <span className={classes.confirmationCode}>{email}</span></p>
        </div>
        <div className={classes.addCalender}>
          <button
            className={classes.addCalendarButton}
            onClick={handleAddToCalendar}
          >
            <div className={` ${classes.addCalendarContent}`}>
              <CalendarIcon />
              <div className={classes.addCalenderText}>Add to Calendar</div>
            </div>
          </button>
        </div>
        <div className={classes.bookingDetailsBox}>
          <BookingDetails
            companyLabel={company}
            branchName={location}
            bookingTimeSelectedDate={moment(selectedDate).format("dddd, MMMM DD")}
            serviceName={service}
            bookingTimeStartSlotTime={setTimeZone(startSlotTime)}
            servicePrice={servicePrice}
            bookingCode={bookingId}
            durationTime={processDuration(durationTime)}
          />
        </div >
        
        <div className={classes.backToHome}>
          <CustomButton
            onClick={handleBacktoHome}
            confirm={true}
          >
            back to home
          </CustomButton>
        </div>
      </div>

      <Footer />
    </div>
    </>
  );
};

export default ConfirmationPage;
