import { makeStyles } from "@material-ui/core";
import React from "react";
import Grid from "@material-ui/core/Grid";
import MasteredCard from "../assets/images/mc_vrt_pos.svg";
import VisaCard from "../assets/images/Visa.png";
import { ReactComponent as Logo } from "../assets/images/wakelogo.svg";
import { withRouter, useHistory } from "react-router-dom";
import { directiveLiteral } from "@babel/types";

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    backgroundColor: "#332F30",
    marginTop: 20,
    width: `calc(100% - 40px)`,
    padding: 20,
  },
  footerContainerLogo: {
    backgroundColor: "#FFF",
    marginTop: 20,
    width: `calc(100% - 0px)`,
  },
  footerContainerNoMarginLogo: {
    backgroundColor: "#FFF",
    width: `calc(100% - 0px)`,
  },
  footerContainerNoMargin: {
    backgroundColor: "#332F30",
    width: `calc(100% - 40px)`,
    padding: 20,
  },
  companyName: {
    fontFamily: "Martel Sans",
    fontSize: 20,
    fontWeight: "700",
    margin: 0,
    userSelect: "none",
    color: "white",
  },
  address: {
    width: "100%",
    textAlign: "center",
    fontFamily: "Martel Sans",
    margin: 0,
    fontWeight: "700",
    userSelect: "none",
    color: "white",
    fontSize: 16.5,
    marginTop: 15,
  },
  phone: {
    textDecoration: "none",
    color: "#FFF",
  },
  action: {
    fontFamily: "Martel Sans",
    fontSize: 20,
    margin: 0,
    fontWeight: "700",
    cursor: "pointer",
    userSelect: "none",
    color: "white",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  itemsList: {
    justifyContent: "center",
    alignItems: "center",
    margin: "20px auto 10px auto",
    flexDirection: "column",
  },
  cardList: {
    justifyContent: "center",
    alignItems: "center",
    margin: "20px auto 10px auto",
  },
  termCondition: {
    color: "#FFFFFF",
    fontFamily: "airbnb-cereal-app",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 15,
    paddingLeft: "5px",
    textWrap: "wrap",
    "& > a": {
      color: "#FFFFFF",
      textDecoration: "none",
      cursor: "pointer",
    },
  },
  address: {
    color: "#FFFFFF",
    fontFamily: "airbnb-cereal-app-light",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
    padding: "0 10px",
    textWrap: "wrap",
    textAlign: "center",
    lineHeight: "23px",
  },
  map: {
    textDecoration: "none",
    color: "#FFFFFF",
    padding: "0 5px",
  },
  logoICon: {
    cursor: "pointer",
  },
}));

const Footer = (props) => {
  const classes = useStyles();
  const history = useHistory();
  // const handleLogo = () => {
  //   history.push("/");
  // };
  return (
    <>
      <div
        className={
          props.homePage
            ? classes.footerContainerNoMarginLogo
            : classes.footerContainerLogo
        }
      ></div>
      <div
        className={
          props.homePage
            ? classes.footerContainerNoMargin
            : classes.footerContainer
        }
      >
        <Grid item xs={12} lg={12} md={12}>
          <Grid container className={classes.itemsList}>
            <div className={classes.termCondition}>
              {" "}
              <a href="/aboutus" target="_blank">
                About Us
              </a>{" "}
              |{" "}
              <a href="/refund" target="_blank">
                Refund Policy
              </a>
            </div>
            <div className={classes.termCondition}>
              <a href="/privacy" target="_blank">
                Privacy Policy
              </a>{" "}
              |{" "}
              <a href="/terms" target="_blank">
                Terms & Conditions
              </a>{" "}
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12} md={12}>
          <Grid container className={classes.itemsList}>
            <div className={classes.address}>
              iSKI Center, Deira Islands, Dubai, UAE
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12} md={12}>
          <Grid container className={classes.cardList}>
            <img src={MasteredCard} alt="VisaCard" />
            <img src={VisaCard} alt="VisaCard" />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Footer;
